<template>
    <div><ShStepOne kind="offer" /></div>
</template>

<script lang="ts" setup>
definePageMeta({
    middleware: ['auth-required'],
})

const { $lang } = useNuxtApp()

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

const title = $lang.pages.compartirOferta.title_seo
const description = $lang.pages.compartirOferta.description_seo

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>
